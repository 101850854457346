import React, { useState, useEffect } from 'react';
import './Checkout.css';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Checkout = ({ selectedPlan }) => {
  const [cardData, setCardData] = useState({
    cardNumber: '',
    expirationDate: '',
    cardCode: '',
  });
  const navigate = useNavigate();

  // Dynamically load Accept.js from Authorize.Net
  useEffect(() => {
    const scriptSrc = "https://js.authorize.net/v1/Accept.js"; // Use the sandbox environment for testing
    const loadAcceptJs = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => console.log("Accept.js loaded successfully");
      document.body.appendChild(script);
    };

    loadAcceptJs(scriptSrc);
  }, []);

  const [billingData, setBillingData] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    postcode: '',
    country: '',
    email:' '
  });
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBillingData(prevState => ({
      ...prevState,
      [name]: value.trim()
    }));
    setCardData(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value.trim()
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const authData = {
      clientKey: '82uAQdWg2YJe8AS3n4F7V89B6cTMTz6vGaNMP6Pakj28rY5RKLC4kNfvt48fvv8X', // Sandbox credentials
      apiLoginID: '59kDYh7y',
    };
  
    const { cardNumber, expirationDate, cardCode } = cardData;
    const [month, year] = expirationDate.split('/');
    const secureData = {
      authData,
      cardData: {
        cardNumber,
        month,
        year,
        cardCode,
      },
    };
  
    console.log(secureData);
    
  
    // Call the processPayment function if Accept.js is loaded and ready
    if (window.Accept) {
      window.Accept.dispatchData(secureData, async (response) => {
        if (response.messages.resultCode === 'Ok') {
          await processPayment(response.opaqueData.dataDescriptor, response.opaqueData.dataValue);
        } else {
          console.error('Error:', response.messages.message);
        }
      });
    } else {
      console.error('Accept.js not loaded');
    }
  };
  

  const processPayment = async (dataDescriptor, dataValue) => {
    console.log('Processing payment with opaque data:', { dataDescriptor, dataValue });
    const { firstName, lastName, streetAddress, city, postcode, country, email } = billingData;
    // Make an API call to your backend with the opaque data
    console.log(selectedPlan.price)
    try {
      const response = await axios.post('https://backend-ruby-beta.vercel.app/api/process-payment', {
        dataDescriptor,
        dataValue,
        amount: parseInt(selectedPlan.price.match(/\d+/g).join(''), 10),
        billingDetails: {
          firstName,
          lastName,
          streetAddress,
          city,
          postcode,
          country,
          email
        }
      });
      if (response.data.message) {
        toast.success('Payment successful!', {
          onClose: () => navigate('/'),  // Navigate when the toast closes
          autoClose: 5000  // Toast will auto-close after 5000 ms
        });
        console.log('Payment successful:', response.data);      
        // Additional success logic here
      } else {
        throw new Error('Payment processing failed');
      }
    } catch (error) {
      toast.error(`Payment failed: ${error.response ? error.response.data.error : error.message}`);
      console.error('Payment error:', error);
    }
    
  };// Make sure to import the CSS file


  return (
    <div className="checkout-page">
     <ToastContainer />
      <h1 className="checkout-title">Checkout</h1>
      <div className="checkout-content">
        <form onSubmit={handleSubmit} className="billing-details">
          <h2>Billing Details</h2>
          <input type="text" name="firstName" placeholder="First name *" onChange={handleInputChange} />
  <input type="text" name="lastName" placeholder="Last name *" onChange={handleInputChange} />
  <input type="text" name="country" placeholder="Country *" onChange={handleInputChange} />
  <input type="text" name="streetAddress" placeholder="Street address *" onChange={handleInputChange} />
  <input type="text" name="city" placeholder="Town / City *" onChange={handleInputChange} />
  <input type="text" name="postcode" placeholder="Postcode / ZIP *" onChange={handleInputChange} />
  <input type="email" name="email" placeholder="Email address *" onChange={handleInputChange} />

        </form>


        <div className="order-details">
          <h2>Your Order</h2>
          <div className="order-item">
            <span>{selectedPlan.name} Account Price</span>
            <span>{selectedPlan.price}</span>
          </div>
          <div className="payment-method">
            <label htmlFor="cardNumber">Card number *</label>
            <input type="text" id="cardNumber" placeholder="Card number *" name='cardNumber' onChange={handleInputChange} />
            <label htmlFor="expiry">Expiry (MM/YY) *</label>
            <input type="text" id="expiry" placeholder="Expiry (MM/YY) *" name='expirationDate' onChange={handleInputChange} />
            <label htmlFor="cvc">Card code (CVC) *</label>
            <input type="text" id="cvc" placeholder="Card code (CVC) *" name='cardCode' onChange={handleInputChange} />
            <button type="submit" className="place-order-button" onClick={handleSubmit}>Place order</button>
          </div>
        </div>
      </div>
      <footer className="checkout-footer">
        © {new Date().getFullYear()} All rights reserved.
      </footer>
    </div>
  );
};

export default Checkout;
