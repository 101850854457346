import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import Checkout from './components/Checkout'; // Assuming Checkout component is also in 'components' folder

// Define pricingPlans outside of the component
const pricingPlans = {
  '1k': { price: '$25', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/1k' },
  '2.5k': { price: '$50', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/2.5k' },
  '5k': { price: '$75', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/5k' },
  '10k': { price: '$100', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/10k' }
  
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/checkout/1k" element={<Checkout selectedPlan={pricingPlans['1k']} />} />
        <Route path="/checkout/2.5k" element={<Checkout selectedPlan={pricingPlans['2.5k']} />} />
        <Route path="/checkout/5k" element={<Checkout selectedPlan={pricingPlans['5k']} />} />
        <Route path="/checkout/10k" element={<Checkout selectedPlan={pricingPlans['10k']} />} />
       
      </Routes>
    </Router>
  );
}

export default App;