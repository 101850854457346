import React , { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './HomepageStyles.css';
import logo from '../img/Logo.jpeg';
import low from '../img/Low.jpg';
import trade from '../img/Trade.jpg';
import bg from '../img/bg.jpg';



const useOnScreen = (ref, rootMargin = '0px') => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the element is intersecting
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Stop observing the element after it becomes visible
          observer.unobserve(entry.target);
        }
      },
      {
        root: null, // viewport is the default root
        rootMargin,
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, rootMargin]); // Dependencies

  return isVisible;
};
const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const LearningSection = () => {
  const sectionRef = useRef(null);
  const isVisible = useOnScreen(sectionRef);

  return (
    <motion.section
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    variants={sectionVariants}
    className="learning-section-class"
  >
    <section
      ref={sectionRef}
      className={`bg-black text-white py-12 px-4 lg:px-20 transition-transform duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center gap-12">
        {/* Text Content */}
        <div className="lg:w-1/2 space-y-6">
          <h2 className="text-5xl font-bold">There is a reason why we are #1</h2>
          <h3 className="text-3xl font-bold text-purple-600">100% Profit Share is here</h3>
         
          <li className="text-gray-300">
No restrictions, No time Limits </li>
          <li className="text-gray-300">
7 days Payouts</li>
          <a href="#" className="inline-block bg-purple-600 hover:bg-purple-700 text-xl font-semibold px-6 py-3 rounded-full transition duration-300">Get Started</a>
        </div>
        {/* Image Container */}
        <div className="lg:w-1/2 flex justify-center lg:justify-end">
  <div className="w-full lg:max-w-md h-96 bg-gray-800 rounded-lg shadow-xl p-6 flex items-center justify-center">
    {/* Replace the span with this img tag */}
    <img src={low} alt="Interactive UI" className="w-full h-full object-cover rounded-lg dark-image" />
  </div>
</div>

      </div>
      
    </section>
    </motion.section>
    
  );
};


const AdvantagesSection = () => {
  // Removed the visibility hook for simplicity, as it was causing issues
  // If needed, you can add it back following the pattern from LearningSection

  return (
    <motion.section
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    variants={sectionVariants}
    className=" Advantages-section-class"
  >
    <section className="bg-black text-white py-12 px-4 lg:px-20">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row-reverse items-center gap-12">
        
        {/* Text Content on the right */}
        <div className="lg:w-1/2 space-y-6">
          <h2 className="text-5xl font-bold"><br></br>Discover the Advantages of Our Trading Challenge Platform</h2>
          <p className="text-gray-300 mb-8">
            With our platform, you can participate in trading challenges and gain valuable experience in a risk-free environment. Our intuitive interface and advanced tools make it easy to analyze market trends and develop winning strategies.
          </p>
          {/* Sub-points */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-gray-300">
            <div>
              <h4 className="text-3xl font-bold text-purple-600">50% Faster</h4>
              <p>Execute trades and manage your portfolio with lightning speed.</p>
            </div>
            <div>
              <h4 className="text-3xl font-bold text-purple-600">50% Easier</h4>
              <p>Our user-friendly platform simplifies the trading process for beginners.</p>
            </div>
          </div>
        </div>

        {/* Image Container on the left */}
        <div className="lg:w-1/2 flex justify-center lg:justify-end">
  <div className="w-full lg:max-w-md h-96 bg-gray-800 rounded-lg shadow-xl p-6 flex items-center justify-center">
    {/* Replace the span with this img tag */}
    <img src={trade} alt="Interactive UI" className="w-full h-full object-cover rounded-lg dark-image" />
  </div>
</div>
      </div>
    </section>
    </motion.section>
  );
};


const InformationSection = () => {
  const sectionRef = useRef(null);
  const isVisible = useOnScreen(sectionRef);

  return (
    <motion.section
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    variants={sectionVariants}
    className="Information-section-class"
  >
    
    <section
      ref={sectionRef}
      className={`bg-black text-white py-12 transform transition-transform duration-500 ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-center text-5xl font-bold mb-12 ">How we work</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* Each Step Container */}
          {['Sign up', 'Pass Challenge', 'Become a Trader'].map((step, index) => (
            <div key={index} className="flex flex-col items-center md:items-start p-6 bg-gray-800 rounded-lg glow-container">
              <div className="mb-4 icon-container">
                {/* Placeholder for icon, replace with actual icon component */}
                <span className={`text-4xl ${step === 'Sign up' ? 'text-purple-600' : step === 'Pass Challenge' ? 'text-green-500' : 'text-blue-500'}`}>ⓘ</span>
              </div>
              <h3 className="text-3xl font-bold mb-3 text-purple-600">{step}</h3>
              <p>
                {step === 'Sign up' && "Immediately after you start your Challenge you will receive an e-mail with your trading credentials."}
                {step === 'Pass Challenge' && "As soon as you pass phase 2 and your results are verified, you will be able to trade on your account."}
                {step === 'Become a Trader' && "Do your best, trade safe and receive up to 90% of your profits on our Scaling plan if you consistently generate long term results."}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
     </motion.section>
  );
};


const PricingSection = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const pricingPlans = {
    '1k': { price: '$25', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/1k' },
    '2.5k': { price: '$50', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/2.5k' },
    '5k': { price: '$75', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/5k' },
    '10k': { price: '$100', profitTarget: '8%', maxDailyLoss: '5%', maxLoss: '10%', checkoutLink: '/checkout/10k' }
   
  };

  
  const handlePlanSelection = (planKey) => {
    setSelectedPlan(pricingPlans[planKey]);
  };

  return (
    <motion.section
    id="pricingSection"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    variants={sectionVariants}
    className="Pricing-section-class"
  >
    <section className="bg-black text-white py-12 px-4 lg:px-20">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-center text-5xl font-bold mb-12">Let's Get Started</h2>
        <p className="mb-8">Choose an account size, Get funded up to 10K in simulated funding instantly.</p>
        <div className="mb-8">
          {/* Plan selection buttons */}
          {Object.keys(pricingPlans).map((planKey) => (
            <button
              key={planKey}
              onClick={() => handlePlanSelection(planKey)}
              className={`px-4 py-2 mx-2 rounded-lg font-semibold transition duration-300 ${
                selectedPlan?.checkoutLink === pricingPlans[planKey].checkoutLink
                  ? 'bg-purple-600 hover:bg-purple-700'
                  : 'bg-gray-700 hover:bg-gray-600'
              }`}
            >
              {planKey}
            </button>
          ))}
        </div>
        {selectedPlan && (
          <div className="pricing-details-container mx-auto mb-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              {/* Pricing details */}
              <div>Profit Target: {selectedPlan.profitTarget}</div>
              <div>Max Daily Loss: {selectedPlan.maxDailyLoss}</div>
              <div>Max Loss: {selectedPlan.maxLoss}</div>
            </div>
            <div className="mb-4">
              <span className="text-3xl font-bold">{selectedPlan.price}</span>
            </div>
            <button
              onClick={() => window.location.href = selectedPlan.checkoutLink}
              className="get-started-button"
            >
              Let's go
            </button>
          </div>
        )}
      </div>
    </section>
    </motion.section>
  );
};


const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const faqs = [
    {
      question: 'How does it work?',
      answer: 'Our trading challenges are designed to test your skills and knowledge. You can participate by signing up on our platform and selecting a challenge that suits your interests. Once you join a challenge, you\'ll have access to all the necessary tools and resources to make informed trading decisions.'
    },
    {
      question: "Are there monthly fees?",
      answer: "Unlike other props, we charge no monthly fees. Our program is a one-time fee."
    },
    {
      question: "Is there a time limit?", 
      answer: "You have no time limit on our evaluation programs, just make sure to keep your account active by placing at least one simulated trade on the account every 90 days."
    },
    {
      question: "How do payouts work?",
      answer: "After you pass your evaluation, you can request a payout every 21 days, either through bank account transfer or cryptocurrency"
    },
    {
      question: "Can I trade on multiple accounts?",
      answer: "You can trade on as many evaluations at a time, as long as your max allocation does not exceed $25,000."
    }
  ];

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <motion.section
    id="faqSection"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
    variants={sectionVariants}
    className="FAQ-section-class"
  >
    <section className="bg-gray-800 text-white py-12 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-10">FAQs</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <p className="text-1xl md:text-2xl  mb-1">Find answers to common questions about trading challenges, platform and usage.</p>
          </div>
          <div>
            {faqs.map((faq, index) => (
              <div key={index} className="mb-6">
                <h3
                  className="text-lg font-semibold cursor-pointer transition-colors duration-300 hover:text-purple-500"
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                </h3>
                <div
                  className={`mt-2 transition-all duration-300 ${
                    index === activeIndex ? 'max-h-full opacity-100' : 'max-h-0 opacity-0'
                  } overflow-hidden`}
                >
                  <p className="text-gray-200">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    </motion.section>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-brand">
        <img src={logo} alt="Easyway Trader Logo" className="your-custom-classes" />
          {/* <h1>Easyway Trader</h1> */}
          <p>Funding for traders</p>
          <div className="footer-social-links">
            {/* Add icons here */}
          </div>
        </div>
        {/* <div className="footer-links">
          <div className="footer-column">
            <h4>LEGAL</h4>
            <a href="#">Terms</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Refund Policy</a>
          </div>
        </div> */}
        <div className="footer-payment-options">
        
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-disclaimer">
          It's crucial to differentiate between purchasing a funded account from Easyway Trader and making a deposit with a financial institution. The fees you pay for our programs are not deposits. Easyway Trader does not offer financial advice and does not insure or deal in financial products. All trading will occur on demo accounts only / simulated live trading conditions. Easyway Trader does not offer services to clients from the USA.
        </p>
        <p className="footer-credit">
          © All rights reserved 2024. Easyway Trader is a product of Easyway Shipper LLC is registered in Executive Office 90 Brewery Road, New city, NY 10956.
        </p>
      </div>
    </footer>
  );
};



const Homepage = () => {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div style={backgroundStyle} className="flex flex-col min-h-screen bg-black">
      {/* Navigation bar */}
      <header className="w-full py-6 fixed top-0 z-10 px-12 flex justify-between items-center bg-opacity-90">
          
        {/* <h1 className="text-4xl font-bold">Easyway Trader</h1> */}
        <img src={logo} alt="Easyway Trader Logo" className="your-custom-classes" />
        <nav className="flex items-center">
          <a href="#" className="nav-link">Home</a>
          <a href="#challenge" className="nav-link" onClick={(e) => {
    e.preventDefault();
    document.getElementById('pricingSection').scrollIntoView({ behavior: 'smooth' });
  }}>Challenge</a>
         <a href="#faq" className="nav-link" onClick={(e) => {
    e.preventDefault();
    document.getElementById('faqSection').scrollIntoView({ behavior: 'smooth' });
  }}>FAQ</a>
          <button className="cta-button">Login</button>
        </nav>
      </header>

    {/* Main content */}
    <main className="flex flex-col items-center justify-center flex-1 pt-24 text-center px-4 md:px-20">
      <h2 className="text-6xl font-bold text-white mb-4">
      <br></br>Join Us And Fund Your Dreams
      </h2>
      <p className="text-xl text-gray-300 mb-8">
        Enjoy The Most Competitive Trading Conditions In The Industry.
      </p>
      <a href="#" className="bg-gradient-to-r from-blue-700 to-purple-800 text-white font-bold py-3 px-8 rounded-full transition-colors">
        Become A Funded Trader → 
      </a>
      <br></br><br></br><br></br>
    </main>


         {/* Learning Section */}
      <LearningSection />

       {/* Advantages Section */}
      <AdvantagesSection />

       {/* Information Section */}
       <InformationSection />

       {/* Pricing Section */}
      <PricingSection />

      <FAQSection />

    
        <Footer Section/>
     
    </div>
  );
};


export default Homepage;



